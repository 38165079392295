
.image-container img {
    width: 100%;
}
::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #a3a3a3;
    border: 3px solid #fff;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}
