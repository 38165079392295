:root {
    --custom-padding-left: 0px;
    --custom-padding-bottom: 0px;
    --custom-width: '100%';
}

a.active {
    text-decoration: none;
    border-bottom: 3px solid #ff80ab;
}
a.active:hover {
    text-decoration: none;
}
body  {
    padding-left: var(--custom-padding-left) !important;
    padding-bottom:   var(--custom-padding-bottom) !important;
    width: var(--custom-width) !important;
}
.MuiContainer-root {
    width: var(--custom-width) !important;
}


