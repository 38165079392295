/*
.MuiTable-root @media (min-width: 1000px) {

}*/
/* .MuiToolbar-gutters {
    width: 1000px;
}*/
/*.Cell-brightRightBorder::after{
    width: 400px;
}
.makeStyles-cell::after{
    width:200px
}*/
/*.MuiTableCell-body		{
    padding-right: 15px
}*/
/*
MuiTableCell-root MuiTableCell-body makeStyles-cell-5601*/
/*.Cell-brightRightBorder-2840{
    width: 900px;
}
.makeStyles-cell-2967 {
    width: 300px;
}*/
.dx-scheduler-timeline .dx-scheduler-date-table-cell {
    width: 500px;
}
.appointmentRaise {
    transition: transform ease-in-out 0.2s;
    position: fixed;
}
.appointmentRaise:hover {
    transform: scale(1.0025);
    position: fixed;
    border: white 1px solid;
    box-shadow: white 0px 0px 5px;
}
