.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.sc-AxirZ {
    z-index: 120 !important;
}

.calender-disabled-date {
    background-image: repeating-linear-gradient(
            135deg,
            rgba(244, 67, 54, 0.1),
            rgba(244, 67, 54, 0.1) 4px,
            transparent 4px,
            transparent 9px
    );
    color: #9b6467;
}

.power-bi-report {
    height: 900px;
    width: 100%;
}
